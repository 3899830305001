export enum StatusCodes {
  UNAUTHORIZED = 401,
  FORBIDDEN = 403,
  OK = 200,
}

export enum AuthStatus {
  UNAUTHORIZED = 'UNAUTHORIZED',
  OK = 'OK',
  '2FA' = '2FA',
}

export enum AuthFormValues {
  LOGIN = 'username',
  PASSWORD = 'password',
  GA = 'ga',
}

export enum Service {
  Backoffice = 'backoffice',
  Symboldb = 'symboldb',
  CorporateActions = 'corporate-actions',
  Notifications = 'notifications',
  Reporting = 'reporting',
  Crm = 'crm',
  Cprm = 'CPRM',
  Workflow = 'workflow',
  Reconciliation = 'reconciliation',
  Branding = 'branding',
  Auditlog = 'auditlog',
  Remus = 'remus',
  StressTest = 'risk-management',
  OrderManagement = 'order-management',
}

export const ServiceMenuMap: { [key: string]: string } = {
  bo: Service.Backoffice,
  symboldb: Service.Symboldb,
  'symboldb-ui': Service.Symboldb,
  'corporate-actions': Service.CorporateActions,
  ns: Service.Notifications,
  reporting: Service.Reporting,
  crm: Service.Crm,
  cprm: Service.Cprm,
  wfe: Service.Workflow,
  recon: Service.Reconciliation,
  branding: Service.Backoffice,
  'order-management': Service.OrderManagement,
  auditlog: Service.Backoffice,
  remus: Service.Remus,
  'stress-test': Service.StressTest,
};

export const AUTH_SERVICE = Service.Backoffice;
export const CRM_SERVICE = Service.Crm;
export const SHAPER_SERVICE = Service.Notifications;
export const CPRM_SERVICE = Service.Cprm;
export const SERVICES = Object.values(Service);

export enum NotificationStatus {
  UNAUTHORIZED = 'error',
  OK = 'success',
  '2FA' = 'warning',
}

export enum NotificationMessage {
  UNAUTHORIZED = 'Authentication failed:',
  OK = 'Successfully authorized in services:',
  '2FA' = 'Needs two-factor authentication:',
  'PASSWORD' = 'Wrong username or password.',
}

export enum FormState {
  AUTH,
  RESTORE,
  GA,
  SMS,
  EMAIL,
}

export const GOOGLE_AUTH_SERVICES = [
  Service.Workflow,
  Service.Backoffice,
  Service.Symboldb,
  Service.Reconciliation,
  Service.Crm,
  Service.Reporting,
  Service.Cprm,
  Service.CorporateActions,
  Service.Notifications,
  Service.Branding,
  Service.Auditlog,
  Service.Remus,
  Service.StressTest,
  Service.OrderManagement,
];

export const CHECK_TOKEN_INTERVAL = 180000;
export const MINIMAL_TOKEN_DIFF = 10;
export const DEFAULT_CLIENT_ID = 'checkpoint';
export const LOCAL_STORAGE_KEY_LAST_PAGE = 'lastPage';
export const LOCAL_STORAGE_KEY_CONNECT_TOKEN = 'connectToken';
export const LOCAL_STORAGE_KEY_CONNECT_PROVIDER = 'connectProvider';
